<template>
  <v-sheet
    height="100%"
    :style="`background-image: url('${require('../../../../../public/static/default/login-background.jpg')}')`"
  >
    <v-container class="fill-height justify-center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="7">
          <v-sheet class="with-radius">
            <v-card-text class="pa-0">
              <v-row class="ma-0">
                <v-col cols="12">
                  <div class="my-4 mx-auto text-center">
                    <div class="text-h4 d-flex justify-center align-center">
                      <!-- <img
                        src="/static/default/logo_gris--solo-nube.svg"
                        width="60"
                        alt="logo"
                      /> -->
                      ¡Hola, bienvenido(a)!
                    </div>
                    <!-- <div class="subtitle-1 mt-3">Hola, ¡Bienvenido!</div> -->
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pa-2 d-flex align-center"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <v-img
                    src="/static/default/login-shape.png"
                    lazy-src="/static/illustator/working_late.png"
                  />
                  <!-- <slider /> -->
                  <!-- <v-row align="center" justify="center" class="fill-height ma-0">
                  </v-row> -->
                </v-col>
                <!-- 2 -->
                <v-col cols="12" md="6">
                  <v-sheet max-width="380" class="mx-auto" color="transparent">
                    <v-form @submit.prevent="$v.$invalid ? null : submit()" ref="form">
                      <v-text-field
                        class="mb-3"
                        :error-messages="fieldErrors('form.email')"
                        @input="$v.form.email.$touch()"
                        @blur="$v.form.email.$touch()"
                        prepend-inner-icon="email"
                        v-model="form.email"
                        label="Correo electrónico"
                        autocomplete="email"
                        flat
                      />
                      <v-text-field
                        class="mt-3"
                        :error-messages="fieldErrors('form.password')"
                        :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPwd ? 'text' : 'password'"
                        @input="$v.form.password.$touch()"
                        @blur="$v.form.password.$touch()"
                        prepend-inner-icon="vpn_key"
                        v-model="form.password"
                        label="Contraseña"
                        @click:append="showPwd = !showPwd"
                        flat
                      />
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <v-checkbox
                          v-model="form.rememberMe"
                          label="Recuérdame"
                          class="mt-0"
                        />
                        <router-link to="/forgot-password" class="py-1 no-text-decoration"
                          >Olvidé mi contraseña
                        </router-link>
                      </div>
                      <v-btn block type="submit" color="primary" :disabled="$v.$invalid"
                        >Entrar</v-btn
                      >
                      <!-- <div class="text-center my-5">
                        ¿Aún no tienes cuenta?
                        <router-link to="/signup" class="py-1 no-text-decoration"
                          >Crear cuenta</router-link
                        >
                      </div> -->
                    </v-form>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>¡Iniciaste sesión exitosamente!</span>
      <v-icon dark>check_circle</v-icon>
    </v-snackbar>
  </v-sheet>
</template>

<script>
  // Validations
  import { required, email, minLength } from 'vuelidate/lib/validators'
  import validationMixin from '@/mixins/validationMixin'

  // Encrypt
  import { encryptData } from '@/utils/encryption'

  const defaultForm = {
    email: '',
    password: '',
    rememberMe: false,
  }
  export default {
    mixins: [validationMixin],
    validations: {
      form: {
        email: { required, email },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    },
    validationMessages: {
      form: {
        email: {
          required: 'Por favor, introduce un correo electrónico',
          email: 'El correo electrónico debe ser válido',
        },
        password: {
          required: 'Por favor, introduce una contraseña',
          minLength: '6 caracteres como mínimo',
        },
      },
    },
    data() {
      return {
        form: Object.assign({}, defaultForm),
        showPwd: false,
        snackbar: false,
        loading: false,
      }
    },
    methods: {
      submit() {
        this.login()
        this.resetForm()
        this.$v.$reset()
      },
      resetForm() {
        this.form = Object.assign({}, defaultForm)
        this.$refs.form.reset()
      },
      login() {
        this.$axios
          .post('auth/login', {
            email: this.form.email,
            password: this.form.password,
          })
          .then((response) => {
            try {
              this.$session.start()
              this.$session.set('tokenSession', encryptData(response.data.access_token))
              this.$session.set('userId', encryptData(response.data.user.id))
              this.$session.set('userName', encryptData(response.data.user.name))
              this.$session.set('userLastname', encryptData(response.data.user.lastname))
              this.$session.set('userAvatar', encryptData(response.data.user.avatar))
              this.$session.set('userModules', encryptData(response.data.user.module))
              this.$session.set(
                'companies',
                encryptData(response.data.user.companies_allowed)
              )
              this.$session.set('roles', encryptData(response.data.user.roles))
              this.$session.set('userCompany', encryptData(response.data.user.company))
              this.$session.set(
                'userCurrentCompany',
                encryptData(response.data.user.company)
              )

              // VUEX State
              this.$store.commit(
                'setUserCompany',
                encryptData(response.data.user.company)
              )
              this.$store.commit(
                'updateUserModules',
                encryptData(response.data.user.module)
              )
              this.$store.commit('updateUserRoles', encryptData(response.data.user.roles))

              if (this.$route.query.redirect && this.$route.query.redirect !== '') {
                setTimeout(() => {
                  this.$router.push(this.$route.query.redirect)
                }, 1000)
              } else {
                setTimeout(() => {
                  this.$router.push('/')
                }, 1000)
              }
              this.loading = false
              this.snackbar = true
            } catch (error) {
              console.warn(error)
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
    },
  }
</script>
<style lang="scss">
  // .background {
  //   // background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg');
  //   background-image: url('');
  // }
</style>
